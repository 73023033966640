@import '@/_styles/import';
.opener {
  pointer-events: all;
  cursor: pointer;
  padding: 0;
  border: 0;

  + [data-radix-popper-content-wrapper] {
    // Unfortunately, apart from "important" there is no other way to give the necessary specificity to such selector
    z-index: var(--tooltip-z-index, auto) !important;
  }

  &:global(._info) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    padding-top: 2px;

    font-size: rem(15);
    line-height: rem(15);
    color: $white;

    background-color: $lightgrey2;
    border-radius: 50%;

    &::before {
      content: 'i'; // For old browsers
      content: 'i' / '';
    }
  }

  &:global(._inline) {
    display: inline-flex;
    margin: 0 8px;
  }

  &:focus-visible {
    outline: 2px solid var(--tooltip-outline-color, $black);
    outline-offset: var(--tooltip-outline-offset, 0);
  }
}

.popup {
  pointer-events: all;

  min-width: 80px;
  max-width: 250px;
  padding: 20px;

  color: $grey-text;
  text-align: left;

  background-color: $white;
  border-radius: 4px;
  box-shadow:
    0 -3px 12px 0 rgba($black, 0.1),
    0 3px 12px 0 rgba($black, 0.1);

  span svg {
    fill: $white;
  }

  &:global(._info) {
    font-size: rem(12);
    font-weight: normal;
    line-height: rem(16);
  }
}
