@import '@/_styles/import';
.root {
  --card-block-padding: 22px;

  position: relative;

  .portSwap ~ .specialSailingModalTrigger {
    --card-block-padding: calc(22px + 12px + 19.5px);
  }
}

.dates {
  @include font($size: rem(14), $line-height: rem(24), $weight: bold);

  padding-right: 7px;

  .almostSoldOut {
    margin-right: 4px;
    background: none;
  }

  .date {
    white-space: nowrap;
  }
}

.specialSailingModal {
  width: 90vw;

  @include media-breakpoint-down(xs) {
    width: 100vw;
  }
}

.specialSailingModalTrigger {
  @include font($size: rem(13), $weight: 600, $line-height: rem(20), $color: $best-rate-text);

  display: block;

  padding: 0;

  text-align: left;
  text-decoration: underline;

  background: transparent;
  border: 0;

  transition: color 150ms;
}

.specialSailingModalTriggerSrOnly {
  pointer-events: none;

  position: absolute;
  top: 50px;
  left: var(--view-more-dates-inline-padding);

  width: 190px;
  height: 20px;
  padding: 0;

  background: transparent;
  border: 0;
}

.almostSoldOutMessage {
  display: inline-block;
  margin-top: 4px;
  padding: 2px 3px;

  @include font($size: rem(11), $line-height: rem(13), $weight: 400, $style: normal, $color: $black-violet);

  background-color: $urgency-layer-background;
}

.priceAndArrow {
  display: flex;
  gap: 7px;
  justify-content: right;
  text-align: right;

  :global(.Icon) {
    display: grid;
    place-items: center;
    width: 14px;
    fill: $vv-red;
  }

  .priceFromLabel {
    @include font($size: rem(11), $line-height: rem(16), $color: $black-violet);
  }

  .price {
    white-space: nowrap;

    @include font($size: rem(15), $line-height: rem(24), $color: $black-violet);
  }

  .promoPrice {
    color: $red;
  }
}

.swappedPortText {
  @include font($size: rem(13), $color: $grey-text);

  margin: 4px 0 0;
}

.pricedSailingCard {
  cursor: pointer;

  display: block;

  min-height: 80px;
  padding: var(--card-block-padding) var(--view-more-dates-inline-padding);

  color: unset;
  text-decoration: none;

  transition:
    background 0.3s,
    color 0.3s;

  .pricedSailingCardHeader,
  .pricedSailingCardPromo {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 8px;
  }

  &.isSpecialSailing .pricedSailingCardHeader {
    padding-bottom: 0;
  }
}

.root:hover {
  .pricedSailingCard {
    color: $lightwhite;
    text-decoration: none;
    background: $black-violet;
  }

  .specialSailingModalTrigger {
    color: $lightwhite;
  }

  .dates,
  .price,
  .priceFromLabel,
  .promoDiscount,
  .swappedPortText,
  .toPortText {
    color: $white;
  }

  .promoSailingCard {
    background: var(--promo-color-1, $black-violet);
  }

  .promoPrice {
    color: $light-orange;
  }
}

.leadCabinText {
  margin-top: 4px;

  @include font($size: rem(13), $weight: 400, $line-height: rem(16), $color: $lightgrey2);
}
