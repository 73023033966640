@import '@/_styles/import';
.root {
  border-radius: 4px;
  overflow: hidden;
  display: block;
  background-color: $white;

  margin: 12px 0;

  border: 1px solid $neutral-sea-mist;
}

.header {
  background-color: $neutral-sea-mist;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: rem(15);
  line-height: 1.6;

  .tooltipOpener {
    margin: 0 4px;
    width: 16px;
    height: 16px;
  }
}

.headerLabel {
  display: inline-flex;
  align-items: center;
}

.content {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: rem(15);
  line-height: 1.6;
}

.price {
  color: $grey-scale;
  font-size: rem(12);
  line-height: 1.32;
}

.checkbox {
  font-size: rem(15);
  display: flex;
  height: 20px;
  margin: 2px 0;
}
